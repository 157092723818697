import React from "react"
import 'aos/dist/aos.css';
import '../css/styles.css'
import Home from './Home';

const App = () => {
  return (
    <React.Fragment>
      <Home />
    </React.Fragment>
  )
}
export default App;
